<template>
  <div
    class="flex flex-col md:px-6 lg:px-10 sm-ws:px-6 px-4 pt-10 pb-6 mx-auto"
    style="max-width: 1528px"
  >
    <NavBar />
    <div class="flex flex-col mt-12">
      <modalListFile
        @closeModal="
          () => {
            showModal = false;
          }
        "
        :showModal="showModal"
        :current_docs_modal="current_docs_modal"
        :current_title_modal="current_title_modal"
        :current_action_title_modal="current_action_title_modal"
      ></modalListFile>

      <div v-if="!is_api_loading" class="flex w-full flex-col space-y-8">
        <div class="flex w-full lg:flex-col sm:space-y-4">
          <div
            class="
              flex flex-col
              w-1/2
              space-y-8
               pr-20
              sm-ws:pr-16
              lg:px-0 lg:w-full
            "
          >
            <SwiperThumbs
              with_swiper_pagination
              v-if="
                programme.documents.images && programme.documents.images.length
              "
              :with_base_file="true"
              :images="programme.documents.images"
              class="w-full flex-col"
            >
            </SwiperThumbs>
            <div
              v-else
              class="bg-no-repeat bg-cover bg-center rounded-box w-full h-72"
              :style="'background-image: url(/images/default_programme.jpg)'"
            ></div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full text-xs">
                <div class="flex w-1/2 flex-col space-y-4 justify-between">
                  <div class="flex flex-col">
                    <p class="font-bold text-base">{{ programme.adresse }}</p>
                    <p>CP : {{ programme.code_postal }}</p>
                  </div>
                  <div class="flex flex-col">
                    <p>
                      Date d’actabilité :
                      {{ programme.information_programme.date_actabilite }}
                    </p>
                    <p>
                      Date de livraison :
                      {{ programme.information_programme.date_livraison }}
                    </p>
                    <p>Pinel : {{ programme.information_programme.pinel }}</p>
                  </div>
                </div>
                <div
                  class="flex w-1/2 flex-col space-y-4 justify-between sm:pl-3"
                >
                  <div class="flex flex-col items-end">
                    <p>
                      Créé par :
                      {{
                        programme.information_programme.nom_createur_programme
                      }}
                    </p>
                    <p>
                      Tranche : {{ programme.information_programme.tranche }}
                    </p>
                  </div>
                  <div class="flex flex-col items-end">
                    <p>Programme</p>
                    <p class="font-bold text-xl sm:text-base text-promy-purple-200">
                      {{ programme.nom_programme }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="
                  flex
                  w-full
                  justify-between
                  sm:flex-col sm:items-center sm:space-y-3
                "
              >
                <button
                  v-if="all_docs_programme.length"
                  @click="
                    setModalInfos(
                      'Nom de document',
                      'Téléchargement',
                      all_docs_programme
                    )
                  "
                  class="
                    btn-purple
                    px-4
                    py-2
                    uppercase
                    w-fit
                    flex
                    space-x-3
                    sm:w-3/4 sm:justify-center
                    items-center
                    outline-none
                  "
                >
                  <span> Les documents </span>
                  <img
                    src="/images/download.svg"
                    class="w-6 sm-ws:w-5 h-auto"
                  />
                </button>
                <button
                  v-if="videos_visites.length"
                  @click="
                    setModalInfos('Lien de video', 'Visiter', videos_visites)
                  "
                  class="
                    btn-green
                    px-8
                    py-2
                    uppercase
                    w-fit
                    sm:w-3/4 sm:justify-center
                  "
                >
                  Video et visite
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-1/2 space-y-8 lg:w-full lg:mt-6">
            <div class="flex flex-col w-3/5 space-y-3 sm:w-full">
              <span
                class="font-main font-extrabold text-xl text-promy-purple-300"
                >Contact direct</span
              >
              <div class="grid grid-cols-2 gap-4 text-xs text-promy-gray-500">
                <div class="flex space-x-2 h-fit items-center">
                 
                  <MailIcon class=" text-promy-gray-500"></MailIcon>
                  <a
                    :href="
                      'mailto:' +
                      `${programme.information_programme.contact_direct.email}`
                    "
                    target="_blank"
                    class=" flex-1"
                  >
                    <span>
                      {{ programme.information_programme.contact_direct.email }}
                    </span>
                  </a>
                </div>
                <div class="flex space-x-2 h-fit items-center">
                  <PhoneIcon class=" text-promy-gray-500"></PhoneIcon>
                  <a
                    :href="`tel:${programme.information_programme.contact_direct.tel}`"
                    class=" flex-1"
                  >
                    <span>
                      {{ programme.information_programme.contact_direct.tel }}
                    </span>
                  </a>
                </div>
                <div class="flex space-x-2 h-fit items-center">
                  <GlobeAltIcon class=" text-promy-gray-500"></GlobeAltIcon>
                  <a
                    :href="
                      programme.information_programme.contact_direct.site_web
                    "
                    target="_blank"
                    class=" flex-1"
                  >
                    <span>
                      {{
                        programme.information_programme.contact_direct.site_web
                      }}</span
                    >
                  </a>
                </div>

                <div class="flex space-x-2 h-fit items-center">
                  <LocationMarkerIcon class=" text-promy-gray-500"></LocationMarkerIcon>

                  <a
                    :href="
                      `https://maps.google.com/?q=` +
                      `${programme.information_programme.contact_direct.adresse}`
                    "
                    target="_blank"
                    class=" flex-1"
                  >
                    <span>
                      {{
                        programme.information_programme.contact_direct.adresse
                      }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-3">
              <span
                class="font-main font-extrabold text-xl text-promy-purple-300"
                >Description</span
              >
              <p class="text-sm text-promy-gray-600 xl:w-11/12">
                {{ programme.information_programme.description }}
              </p>
            </div>
          </div>
        </div>
        <lots
          :programme="programme"
          @setModalInfos="
            (data) => {
              setModalInfos(data.title, data.action_title, data.docs);
            }
          "
        ></lots>
      </div>
    </div>
  </div>
</template>
<script>
import lots from "./lots";
import modalListFile from "./ModalListFile";
import { MailIcon, PhoneIcon, GlobeAltIcon, LocationMarkerIcon } from '@vue-hero-icons/solid'
export default {
  components: { lots, modalListFile,MailIcon,PhoneIcon,GlobeAltIcon,LocationMarkerIcon },
  data() {
    return {
      docs: [],
      all_docs_programme: [],
      videos_visites: [],
      is_api_loading: true,
      active_accordion: -1,
      showModal: false,
      current_docs_modal: [],
      current_title_modal: "",
      current_action_title_modal: "",
      programme: {},
    };
  },
  created() {
    this.$http
      .get(`programToReserve`, {
        params: {
          programme: this.$route.query.programme,
          id_agence: this.$route.query.id_agence,
          token: this.$route.query.token,
        },
      })
      .then((response) => {
        this.programme = response.data;
        this.is_api_loading = false;
        this.all_docs_programme = [
          ...(this.programme.documents.list_docs
            ? this.programme.documents.list_docs
            : []),
          ...(this.programme.documents.brochures
            ? this.programme.documents.brochures
            : []),
        ];
        this.videos_visites = [
          ...(this.programme.documents.videos
            ? this.programme.documents.videos
            : []),
          ...(this.programme.documents.visites_virtuelle
            ? this.programme.documents.visites_virtuelle
            : []),
        ];
      });
  },
  methods: {
    setModalInfos(title, action_title, docs) {
      this.showModal = true;
      this.current_docs_modal = docs;
      this.current_title_modal = title;
      this.current_action_title_modal = action_title;
    },
  },
};
</script>
