<template>
  <ProModel
    :showModal="showModal"
    @closeModel="
      () => {
        $emit('closeModal');
      }
    "
    classes="w-2/5 w- lg:w-3/4 sm:w-full"
    :is_header_outside_box="true"
    title="Documents"
    classes_container=""
  >
    <div
      class="w-full bg-promy-gray-200 flex justify-between rounded-box flex-col"
    >
      <div
        class="flex justify-between w-full py-5 px-4 text-promy-gray-650 text-xs font-bold space-x-4"
      >
        <div class="flex flex-1">{{ current_title_modal }}</div>
        <div class="flex w-33 justify-center">
          {{ current_action_title_modal }}
        </div>
      </div>
      <div class="flex flex-col overflow-y-scroll" style="max-height: 60vh">
        <div
          v-for="(doc, index) in current_docs_modal"
          :key="index"
          class="flex justify-between w-full py-5 px-4 text-promy-gray-650 text-xs font-bold space-x-4 row-body"
          :class="[index % 2 === 0 ? 'bg-white' : 'bg-promy-gray-125']"
        >
          <a
            class="flex flex-1"
            target="_blank"
            :href="$base_file + '' + doc.value"
            v-if="doc.name"
            >{{ doc.name }}</a
          >
          <a class="flex flex-1" :href="doc.lien" target="_blank" v-else>{{
            doc.lien
          }}</a>
          <a
            class="flex w-33 justify-center"
            :href="doc.value ? $base_file + '' + doc.value : doc.lien"
            target="_blank"
          >
            <img v-if="doc.lien" src="/images/eye-green.svg" class=" sm:w-6"/>
            <img v-else src="/images/download-green.svg" class=" sm:w-6" />
          </a>
        </div>
      </div>
    </div>
  </ProModel>
</template>
<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      require: true,
    },
    current_docs_modal: {
      type: Array,
      require: true,
    },
    current_title_modal: {
      type: String,
      require: true,
    },
    current_action_title_modal: {
      type: String,
      require: true,
    },
  },
};
</script>
<style lang="scss">
.row-body:last-child {
  @apply rounded-b-box;
}
</style>